/* @flow */

import * as React from 'react';

import type {SessionUser} from '../../graphql-types';
import type {EventForTimeline} from './types';
import {
    getEmailTimelineEvent,
    getThreadTimelineEvent,
    TimelineEntryActivity,
    TimelineEntryChat,
    TimelineEntryEmailQueued,
    TimelineEntryNote,
    TimelineEntrySession,
} from './entry';
import {
    getAccountBanner,
    getContactBanner,
    getLeadBanner,
    TimelineBannerAssignment,
    TimelineBannerComment,
    TimelineBannerConstantContact,
    TimelineBannerEmailInteraction,
    TimelineBannerFileUploads,
    TimelineBannerFormSubmission,
    TimelineBannerAccountRelationship,
    TimelineBannerQuote,
    TimelineBannerQuoteView,
} from './banner';
import {TimelineBannerOutreachSent} from './banner/timeline-banner-outreach-sent';

type Props = {
    timelineEvents: EventForTimeline[],
    currentUser: SessionUser,
    entityPageId?: ?string,
    entityPageName?: ?string,
    hasGutterLine?: boolean,
    onDeleteEvent?: (eventId: string) => Promise<*>,
    onRefetchEvents?: () => Promise<*>,
    onSetPinnedStatus?: ({
        entityToPinId: string,
        pinToEntityId: string,
        status: boolean,
        userName: string,
        changeLogId: string,
    }) => Promise<*>,
    numPinnedEvents?: number,
    isDashboard?: boolean,
    onFilterOutEventId?: (filteredEventId: string) => void,
};

function TimelineEventsComponent(props: Props) {
    const {
        timelineEvents,
        currentUser,
        entityPageId,
        entityPageName,
        hasGutterLine,
        onDeleteEvent: onDelete,
        onRefetchEvents: refetchEvents,
        onSetPinnedStatus,
        numPinnedEvents,
        isDashboard,
        onFilterOutEventId,
    } = props;

    const firstEmailIndex = timelineEvents.findIndex(
        (event) => event.payload.__typename === 'Email'
    );

    return (
        <>
            {timelineEvents.map((event, index) => {
                switch (event.payload.__typename) {
                    case 'Note':
                        return (
                            <TimelineEntryNote
                                key={event.id}
                                event={event}
                                currentUser={currentUser}
                                entityPageId={entityPageId}
                                entityPageName={entityPageName}
                                onDeleteEvent={onDelete}
                                onSetPinnedStatus={onSetPinnedStatus}
                                numPinnedEvents={numPinnedEvents}
                                hasGutterLine={hasGutterLine}
                            />
                        );
                    case 'Activity':
                        return (
                            <TimelineEntryActivity
                                key={event.id}
                                event={event}
                                currentUser={currentUser}
                                onDeleteEvent={onDelete}
                                refetchEvents={refetchEvents}
                                hasGutterLine={hasGutterLine}
                            />
                        );
                    case 'Chat':
                        return (
                            <TimelineEntryChat
                                key={event.id}
                                event={event}
                                currentUser={currentUser}
                                onDeleteEvent={onDelete}
                            />
                        );
                    case 'Contact':
                        return getContactBanner({
                            changeType: event.changeType,
                            event,
                        });
                    case 'Account':
                        return getAccountBanner({
                            changeType: event.changeType,
                            event,
                        });

                    case 'AccountRelationshipChange':
                        return <TimelineBannerAccountRelationship key={event.id} event={event} />;

                    case 'Lead':
                        return getLeadBanner({
                            changeType: event.changeType,
                            event,
                            currentUser: currentUser,
                        });
                    case 'Assignment':
                        return <TimelineBannerAssignment key={event.id} event={event} />;
                    case 'ConstantContactContactTrackingActivity':
                        return <TimelineBannerConstantContact key={event.id} event={event} />;
                    case 'EmailInteraction':
                        return <TimelineBannerEmailInteraction key={event.id} event={event} />;
                    case 'Email':
                        return getEmailTimelineEvent({
                            event,
                            currentUser: currentUser,
                            onDelete,
                            hasGutterLine,
                            isDashboard,
                            entityPageId,
                            refetchEvents,
                            isFirstEmail: index === firstEmailIndex,
                            onFilterOutEvent: onFilterOutEventId
                                ? () => onFilterOutEventId(event.id)
                                : undefined,
                        });
                    case 'EmailQueued':
                        return (
                            <TimelineEntryEmailQueued
                                key={event.id}
                                event={event}
                                currentUser={currentUser}
                                onDeleteEvent={onDelete}
                            />
                        );
                    case 'Submission':
                        return <TimelineBannerFormSubmission key={event.id} event={event} />;
                    case 'EntityAttributeValueFile':
                        return <TimelineBannerFileUploads key={event.id} event={event} />;
                    case 'Comment':
                        return (
                            <TimelineBannerComment
                                key={event.id}
                                event={event}
                                currentUser={currentUser}
                            />
                        );
                    case 'Session':
                        return <TimelineEntrySession key={event.id} event={event} />;
                    case 'Thread':
                        return getThreadTimelineEvent({event, entityPageId, entityPageName});
                    case 'Message':
                        return <TimelineBannerOutreachSent key={event.id} event={event} />;
                    case 'Quote':
                        return <TimelineBannerQuote key={event.id} event={event} />;
                    case 'QuoteView':
                        return <TimelineBannerQuoteView key={event.id} event={event} />;
                    default:
                        return (
                            <div
                                style={{
                                    border: '1px solid #ebe8e8',
                                    borderRadius: '3px',
                                    marginBottom: '8px',
                                    padding: '8px',
                                }}
                                key={event.id}
                            >
                                <div>
                                    Event type:&nbsp;
                                    {event.payload.__typename}
                                </div>
                            </div>
                        );
                }
            })}
        </>
    );
}

export const TimelineEvents = React.memo<Props>(TimelineEventsComponent);
