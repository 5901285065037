/* @flow */

export type FeatureName =
    | 'auditLog'
    | 'sqlAccess'
    | 'markets'
    | 'territories'
    | 'salesQuotas'
    | 'activityQuotas'
    | 'automatedEmails'
    | 'activityReport'
    | 'funnelReport'
    | 'emailReport'
    | 'attributionReport'
    | 'lossesReport'
    | 'snapshotReport'
    | 'customReport'
    | 'forecastReport'
    | 'companyHierarchy'
    | 'customizableCreateNew'
    | 'roundRobinScheduler'
    | 'SMS'
    | 'SmsWithLicense'
    | 'leadLimit'
    | 'leadUserAssignment'
    | 'bulkPersonalEmails'
    | 'sharableListsReports'
    | 'shareableEmailTemplates'
    | 'scheduler'
    | 'teams'
    | 'zoom'
    | 'clickToCall'
    | 'salesAutomationGoals'
    | 'salesAutomationTasks'
    | 'summarization'
    | 'transcription'
    | 'abTesting'
    | 'smsCampaigns'
    | 'schedulerFull'
    | 'schedulerFullNonAdmin'
    | 'quotes'
    | 'sso'
    | 'directorySync';

export const featureMessages: {[FeatureName]: string} = {
    auditLog: 'Upgrade your plan to Business or higher to gain access to this feature.',
    sqlAccess: 'Upgrade your plan to Nutshell Enterprise to gain access to this feature.',
    markets: 'Upgrade your plan to Business or higher to add more markets.',
    territories: 'Upgrade your plan to Nutshell Pro or higher to gain access to this feature.',
    salesQuotas: 'Upgrade your plan to Nutshell Growth or higher to gain access to this feature.',
    activityQuotas:
        'Upgrade your plan to Nutshell Growth or higher to gain access to this feature.',
    automatedEmails: 'Upgrade your plan to Nutshell Pro or higher to gain access to this feature.',
    activityReport:
        'Upgrade your plan to Nutshell Growth or higher to gain access to this feature.',
    funnelReport: 'Upgrade your plan to Nutshell Pro or higher to gain access to this feature.',
    emailReport: 'Upgrade your plan to Nutshell Growth or higher to gain access to this feature.',
    attributionReport:
        'Upgrade your plan to Nutshell Pro or higher to gain access to this feature.',
    lossesReport: 'Upgrade your plan to Nutshell Pro or higher to gain access to this feature.',
    snapshotReport: 'Upgrade your plan to Nutshell Pro or higher to gain access to this feature.',
    customReport: 'Upgrade your plan to Nutshell Pro or higher to gain access to this feature.',
    forecastReport: 'Upgrade your plan to Nutshell Pro or higher to gain access to this feature.',
    companyHierarchy: 'Upgrade your plan to Nutshell Pro or higher to gain access to this feature.',
    customizableCreateNew:
        'Upgrade your plan to Nutshell Pro or higher to gain access to this feature.',
    roundRobinScheduler:
        'Upgrade your plan to Nutshell Business or higher to gain access to this feature.',
    SMS: 'Upgrade to Engagement Pro to gain access to this feature.',
    SmsWithLicense:
        'An account admin needs to assign Engagement licenses to start using this feature.',
    leadLimit: 'Upgrade to Nutshell Growth or above to access unlimited open leads.',
    leadUserAssignment:
        'Upgrade your plan to Nutshell Growth or higher to gain access to this feature.',
    bulkPersonalEmails:
        'Upgrade your plan to Nutshell Pro or higher to gain access to this feature.',
    sharableListsReports:
        'Upgrade your plan to Nutshell Pro or higher to gain access to this feature.',
    shareableEmailTemplates:
        'Upgrade your plan to Nutshell Pro or higher to gain access to this feature.',
    clickToCall: 'Upgrade your plan to Nutshell Pro or higher to gain access to this feature.',
    scheduler: 'Upgrade your plan to Nutshell Pro or higher to gain access to this feature.',
    teams: 'Upgrade your plan to Nutshell Growth or higher to gain access to this feature.',
    zoom: 'Upgrade your plan to Nutshell Pro or higher to gain access to this feature.',
    salesAutomationGoals:
        'Upgrade your plan to Nutshell Growth or higher to gain access to this feature.',
    salesAutomationTasks:
        'Upgrade your plan to Nutshell Pro or higher to gain access to this feature.',
    summarization:
        'Upgrade your plan to Nutshell Business or higher to unlock more summarizations.',
    transcription:
        'Upgrade your plan to Nutshell Business or higher to gain unlimited access to this feature.',
    abTesting: 'Upgrade to a Marketing Pro plan to get access to this feature.',
    schedulerFull: 'Upgrade to Nutshell Business or higher to gain access to this feature.',
    schedulerFullNonAdmin:
        'Ask your administrator to upgrade to Nutshell Business or higher to gain access to this feature.',
    quotes: 'Nutshell Quotes is currently in Early Access and available for $45 / month for your entire team.',
    sso: 'You must have a Nutshell Enterprise plan with at least 5 licenses to use single sign-on.',
    directorySync:
        'You must have a Nutshell Enterprise plan with at least 10 licenses to use directory sync.',
};
