/* @flow */

import * as React from 'react';
import classNames from 'classnames';

import {Stack} from 'shells/layout';
import {Body} from 'shells/typography';
import {SparkleOpenIcon} from 'shells/icon';
import {ButtonCard} from 'shells/card';
import {colors} from 'shells/colors';

import type {SuggestedReply} from './types';

type Props = {|
    replies: SuggestedReply[],
    onCardClicked: (reply: SuggestedReply) => void,
    variant?: 'full' | 'sparse',
|};

export function SuggestedReplyCards(props: Props) {
    const {variant = 'sparse'} = props;

    const cardClassNames = classNames('flex full-width', {
        'gap-16': variant === 'full',
        'gap-8': variant === 'sparse',
    });

    return (
        <Stack spacing={8}>
            <Stack spacing={4} isHorizontal={true}>
                <SparkleOpenIcon color={colors.aiBlue} size={13} />
                <Body size='small' color='navy-dk' isBold={true}>
                    Start a reply with AI
                </Body>
            </Stack>
            <div className={cardClassNames}>
                {props.replies.map((reply) => (
                    <ButtonCard
                        key={reply.title}
                        isFullWidth={true}
                        onClick={() => props.onCardClicked(reply)}
                        hasOffWhiteBackground={true}
                    >
                        <div className='pad-8 full-width border-box text-align-left'>
                            <Stack spacing={2}>
                                <Body
                                    size='small'
                                    color='navy-dk'
                                    shouldTruncate={true}
                                    isBold={true}
                                >
                                    {reply.title}
                                </Body>
                                {variant === 'full' && (
                                    <Body size='small' color='navy-dk' shouldTruncate={true}>
                                        {reply.body}
                                    </Body>
                                )}
                            </Stack>
                        </div>
                    </ButtonCard>
                ))}
            </div>
        </Stack>
    );
}
