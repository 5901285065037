/* @flow */

import * as React from 'react';
import {useDispatch} from 'react-redux';

import type {EventFragmentSparse, SessionUser} from 'nutshell-graphql-types';

import {colors} from 'shells/colors';
import type {Lead} from 'shells/timeline/types';

import {appHistory} from '../../../history';
import {openReplyModal} from '../../email/email-reply-actions';
import {EmailRelatedLeadsButton} from '../toolbar/email-related-leads-button';
import {
    getEntryTypeFromPayload,
    getEmailIconVariant,
    getParticipantsForEmail,
    getActorForEmail,
    getStatusForEmail,
    getSubjectForEmail,
    getAdditionalToolbarOptionsForEmail,
    shouldEmailAppearOnLeadTimeline,
} from '../helpers';
import {EmailMutationWrapper} from '../comments/graphql/email-mutation-wrapper';
import {useCreateComment, useDeleteComment} from '../comments/timeline-comment-mutations';
import {useUpdateEmailLeadAssociations} from '../toolbar/use-update-email-lead-associations';
import {useGetRelatedLeads} from '../graphql/hooks/use-get-related-leads';
import {EmailEventActionMenu} from '../email-event-action-menu';

import {TimelineEntry} from './timeline-entry';

type OwnProps = {
    event: EventFragmentSparse,
    currentUser: SessionUser,
    onDeleteEvent?: (eventId: string, allById?: boolean) => Promise<*>,
    hasGutterLine?: boolean,
    isDashboard?: boolean,
    refetchEvents?: ?() => any,
    entityPageId?: ?string,
    onFilterOutEvent?: () => void,
    isFirstReplyableEmail?: boolean,
};

type Props = {
    ...OwnProps,
    onRetryFailedEmail: (emailId: string) => any,
    onUpdateEmail: (emailId: string, shouldShare: boolean) => any,
    onDeleteEmail: (emailId: string, allByAddress?: ?string) => any,
};

export function TimelineEntryEmailComponent(props: Props) {
    const {id, changeType, changeTime, payload, reactions, comments} = props.event;
    const {
        currentUser,
        onRetryFailedEmail,
        onUpdateEmail,
        onDeleteEmail,
        onDeleteEvent,
        hasGutterLine,
        isDashboard,
        refetchEvents,
        entityPageId,
        onFilterOutEvent,
        isFirstReplyableEmail,
    } = props;

    const [isViewingAttachments, setIsViewingAttachments] = React.useState<boolean>(false);
    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

    const createComment = useCreateComment();
    const deleteComment = useDeleteComment();
    const dispatch = useDispatch();
    const {emailRelatedLeads} = useGetRelatedLeads({changeLogId: id});
    const updateEmailRelatedLeadAssociations = useUpdateEmailLeadAssociations(id);

    const onOpenReplyModal = (input: {
        replyToEmailId: string,
        isReplyingAll: boolean,
        onReplySuccess?: () => void,
    }) => {
        dispatch(openReplyModal({...input}));
    };

    const getCustomBorder = (email) => {
        const border = {};
        if (!email.isShared) {
            border.hasDottedBorder = true;
        }
        if (email.isFailed) {
            border.borderColor = colors.red;
        }

        return border;
    };

    const deleteEmailAndEvent = (emailId: string, allByAddress?: ?string) => {
        if (onDeleteEmail) {
            // If removing all by address, we don't want the optimistic removal of
            // the timeline entry because we need the popover to stay open to prompt
            // the user to blocklist the email
            if (allByAddress) {
                return onDeleteEmail(emailId, allByAddress);
            }

            // Note: this is the changeLogId, not the emailId
            // We need this to get the effects of removing a timeline entry
            // e.g. optimistic removal of the timeline entry
            // $FlowIgnore, checked above
            return onDeleteEvent(id);
        }
    };

    /**
     * We need to include this check, even though we are only passing an event
     * with a payload.__typename of 'Email' because Apollo doesn't generate a specific
     * enough type when using a union type (like the TimelineEventPayload).
     * See this issue for more detail:
     * https://github.com/apollographql/apollo-tooling/issues/1223
     */
    if (payload.__typename === 'Email') {
        const disableComments = !payload.isShared;

        const onReply = () => {
            const replyVariables = refetchEvents
                ? {replyToEmailId: payload.id, isReplyingAll: false, onReplySuccess: refetchEvents}
                : {replyToEmailId: payload.id, isReplyingAll: false};

            return onOpenReplyModal(replyVariables);
        };

        const onReplyAll = () => {
            const replyVariables = refetchEvents
                ? {replyToEmailId: payload.id, isReplyingAll: true, onReplySuccess: refetchEvents}
                : {replyToEmailId: payload.id, isReplyingAll: true};

            return onOpenReplyModal(replyVariables);
        };

        const participants = getParticipantsForEmail(
            payload.recipients,
            emailRelatedLeads || undefined
        );
        const relatedLeads: Lead[] =
            emailRelatedLeads && emailRelatedLeads.edges
                ? emailRelatedLeads.edges.map((edge) => ({
                      type: 'leads',
                      id: edge.node.id,
                      name: edge.node.name,
                      avatarUrl: edge.node.avatarUrl,
                      number: edge.node.number,
                      status: edge.node.status,
                      priority: edge.node.priority,
                      htmlUrl: edge.node.htmlUrl,
                      isChecked: edge.isMapped,
                      relatedPerson: edge.node.contacts.primaryEdge
                          ? {
                                type: 'contacts',
                                id: edge.node.contacts.primaryEdge.node.id,
                                name: edge.node.contacts.primaryEdge.node.name,
                                htmlUrl: edge.node.contacts.primaryEdge.node.htmlUrl,
                            }
                          : undefined,
                      relatedCompany: edge.node.accounts.primaryEdge
                          ? {
                                type: 'accounts',
                                id: edge.node.accounts.primaryEdge.node.id,
                                name: edge.node.accounts.primaryEdge.node.name,
                                htmlUrl: edge.node.accounts.primaryEdge.node.htmlUrl,
                            }
                          : undefined,
                  }))
                : [];

        // Check if email entry should be filtered out
        if (onFilterOutEvent && !shouldEmailAppearOnLeadTimeline(emailRelatedLeads, entityPageId)) {
            onFilterOutEvent();
        }

        return (
            <TimelineEntry
                id={id}
                iconVariant={getEmailIconVariant(payload)}
                entryType={getEntryTypeFromPayload(payload)}
                timestamp={changeTime}
                subject={getSubjectForEmail(payload)}
                subjectTitle={payload.subject}
                actor={getActorForEmail(payload.from)}
                status={getStatusForEmail(payload, onRetryFailedEmail, onUpdateEmail)}
                participants={participants}
                relatedLeads={relatedLeads.filter((lead) => lead.isChecked)}
                content={payload.bodyExcerpt}
                changeType={changeType}
                reactions={reactions}
                comments={comments}
                onAddComment={(value) =>
                    createComment({
                        changeLogId: id,
                        parentId: payload.id,
                        value,
                        user: props.currentUser,
                    })
                }
                onDeleteComment={(commentId) => deleteComment({changeLogId: id, commentId})}
                currentUser={currentUser}
                customBorderProps={getCustomBorder(payload)}
                emailEngagement={{
                    attachments: payload.attachments,
                    clicks: payload.trackedClicksCount || 0,
                    opens: payload.trackedViewsCount || 0,
                }}
                disableComments={disableComments}
                actionMenu={
                    <EmailEventActionMenu
                        changeLogId={id}
                        currentUser={currentUser}
                        email={payload}
                        onDeleteEmail={deleteEmailAndEvent}
                        onUpdateEmail={onUpdateEmail}
                        onRetryFailedEmail={onRetryFailedEmail}
                        isViewingAttachments={isViewingAttachments}
                        setIsViewingAttachments={setIsViewingAttachments}
                        onReply={onReply}
                        onReplyAll={onReplyAll}
                    />
                }
                additionalToolbarOptions={getAdditionalToolbarOptionsForEmail(payload, {
                    onReply,
                    onReplyAll,
                    expandable: {
                        isExpanded,
                        setIsExpanded,
                    },
                    relatedLeads:
                        isDashboard || !relatedLeads.length
                            ? undefined
                            : {
                                  toolbarButton: (
                                      <EmailRelatedLeadsButton
                                          relatedLeads={relatedLeads}
                                          updateEmailRelatedLeadAssociations={(leadAssociations) =>
                                              updateEmailRelatedLeadAssociations({
                                                  emailId: payload.id,
                                                  leadAssociations,
                                              })
                                          }
                                      />
                                  ),
                              },
                })}
                onClickOpen={() => {
                    appHistory.push(`#${payload.htmlUrlPath}`);
                }}
                isExpanded={isExpanded}
                hasGutterLine={hasGutterLine}
                // TODO: Use replies from the backend
                suggestedEmailReplies={
                    isFirstReplyableEmail
                        ? [
                              {
                                  id: '1',
                                  title: 'Hello, how are you one?',
                                  body: 'Hello, how are you one?',
                              },
                              {
                                  id: '2',
                                  title: 'Hello, how are you two?',
                                  body: 'Hello, how are you two?',
                              },
                              {
                                  id: '3',
                                  title: 'Hello, how are you three?',
                                  body: 'Hello, how are you three?',
                              },
                          ]
                        : undefined
                }
            />
        );
    }

    return null;
}

export function TimelineEntryEmail(props: OwnProps) {
    const emailId = props.event.payload.__typename === 'Email' ? props.event.payload.id : undefined;

    if (emailId) {
        return (
            <EmailMutationWrapper>
                {({updateEmail, retryFailedEmail, deleteEmail}) => (
                    <TimelineEntryEmailComponent
                        {...props}
                        onUpdateEmail={(id, shouldShare) => {
                            updateEmail(id, shouldShare);
                        }}
                        onRetryFailedEmail={(id) => {
                            retryFailedEmail(id);
                        }}
                        onDeleteEmail={(id, allByAddress) => {
                            deleteEmail(id, allByAddress);
                        }}
                    />
                )}
            </EmailMutationWrapper>
        );
    }

    return null;
}
