/* @flow */

import React from 'react';

type Props = {
    width?: number,
};

export const NutshellMessengerInstagramBubble = ({width = 200}: Props) => {
    return (
        <img
            alt='Nutshell Messenger Instagram bubble'
            style={{width}}
            src='/include/images/nutshell-messenger-instagram-bubble.png'
        />
    );
};
